<template>
<div>
  <NavBar></NavBar>
  <Player></Player>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <audio id="KeyFMAudio" crossorigin="anonymous" preload="none" src=""></audio>
  </div>
</template>

<style>
#keyfm {
  text-align: center;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

/* nav a.router-link-exact-active {
  color: #42b983;
} */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
<script>
import NavBar from '@/components/nav.vue'
import Player from '@/components/Player.vue'
//import $ from 'jquery';
export default {
  components: {
    NavBar,
    Player
  },
  mounted () {
    console.log("test")
  },
  created() {
    var scripts = [
      "https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js",
      "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      tag.setAttribute("type", "module");
      document.head.appendChild(tag);
    });
  }
}
</script>
