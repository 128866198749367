<template>
<div>
    <header :class="'header'">
            <a href="#" class="header__logo">KeyFM</a>
            <ion-icon name="menu-outline" class="header__toggle" id="nav-toggle"></ion-icon>
            <nav class="nav" id="nav-menu">
                <div class="nav__content bd-grid">
                    <ion-icon name="close-outline" class="nav__close" id="nav-close"></ion-icon>
                    <div class="nav__perfil">
                        <div class="nav__img">
                            <img :src="require(`@/assets/img/favicon.svg`)" class="no_drag">
                        </div>
                        <div>
                            <span class="nav__name" style="font-weight: 700;">KeyFM</span>
                        </div>
                    </div>
                    <div class="nav__menu">
                        <ul class="nav__list">
                            <li class="nav__item"><router-link to="/" :class="'nav__link'">Home</router-link></li>
                            <li class="nav__item"><router-link to="/about" :class="'nav__link'">Pepe</router-link></li>
                            <li class="nav__item"><a href="#" class="nav__link">Something</a></li>
                            <li class="nav__item"><a href="#" class="nav__link">Something</a></li>
                            <li class="nav__item"><a href="#" class="nav__link">Something</a></li>
                        </ul>
                    </div>
                    <div class="nav__social">
                        <a href="https://discord.gg/qSggY4eEYn" target="_blank" class="nav__social-icon"><ion-icon name="logo-discord"></ion-icon></a>
                        <a href="https://twitter.com/keyfmradio" target="_blank" class="nav__social-icon"><ion-icon name="logo-twitter"></ion-icon></a>
                        <a href="https://facebook.com/KeyFMNET" target="_blank" class="nav__social-icon"><ion-icon name="logo-facebook"></ion-icon></a>
                    </div>
                </div>
            </nav>
        </header>
        </div>
</template>
<script>
export default {
  name: 'NavBar',
  props: {
  }
}
</script>