<template>
    <div class="playerModule no_select">
        <div class="playerModule__background"><img id="playerModule__artworkBG"/></div>
        <div class="playerModule__song">
            <div class="playerModule__artwork animate__animated animate__slideInDown">
                <img id="playerModule__song__artwork" src="@/assets/img/keyfm_logo.png" class="playerModule__song__artwork no_drag"/>
            </div>
            <div class="playerModule__song__info">
                <div class="playerModule__song__info__artist animate__animated animate__fadeIn">
                    <a id="trackHREF" style="color: inherit;">
                    <span id="playerModule__song__info__artist" class="glow">Loading...</span>
                    </a>
                </div>
                <div class="playerModule__song__info__song animate__animated animate__fadeIn">
                    <span id="playerModule__song__info__song" class="glow">Loading...</span>
                </div>
            </div>
        </div>
        <div class="playerModule__dj">
            <div class="playerModule__dj__avatar animate__animated animate__slideInDown">
                <img id="playerModule__dj__avatar" src="@/assets/img/keyfm_logo.png" class="playerModule__dj__avatar__img no_drag"/>
            </div>
            <div class="playerModule__dj__name glow animate__animated animate__fadeIn">
                <span id="playerModule__dj__name__span">Auto DJ</span>
            </div>
        </div>
        
        <div class="playerModule__controls">
            <span class="playerModule__controls__play" v-on:click.native="togglePlay" id="playBttn">
                <ion-icon name="play"></ion-icon>
            </span>
            <!-- <span class="playerModule__controls__quality">
                <ion-icon name="chevron-down"></ion-icon>
            </span> -->
        </div>

    </div>
</template>
<script>
import $ from 'jquery';
export default {
  name: 'PlayerModule',
  data () {
      return {
          getStats: null
      }
  },
  methods: {
    statData() {
        $.getJSON('https://api.keyfm.net/stats', function(data) {
            $.getJSON('https://api.keyfm.net/cover', function(album) {
                
                console.log(album);
                const albumAPI = album;
                
                console.log(data);
                if ($('#playerModule__artworkBG').attr('src') != albumAPI.cover) {
                    $('#playerModule__artworkBG').attr('src', albumAPI.cover);
                }
                if ($('#playerModule__song__artwork').attr('src') != albumAPI.cover) {
                    $('#playerModule__song__artwork').attr('src', albumAPI.cover);
                }
                if ($('#playerModule__song__info__artist').text() != data["playing"].artist) {
                    $('#playerModule__song__info__artist').text(data["playing"].artist);
                }
                if ($('#playerModule__song__info__song').text() != data["playing"].song) {
                    $('#playerModule__song__info__song').text(data["playing"].song);
                }
                //$('#trackHREF').attr('href', '/track/' + data.now_playing.song.song_id);
                data.currentDJ.autoDJ != true ? $('#playerModule__dj__name__span').text(data.currentDJ.username) : $('#playerModule__dj__name__span').text('Auto DJ');
            });
        });

      this.getStats = setInterval(() => {
        $.getJSON('https://api.keyfm.net/stats', function(data) {
            $.getJSON('https://api.keyfm.net/cover', function(album) {
                
                console.log(album);
                const albumAPI = album;
                
                console.log(data);
                if (albumAPI.cover != null) {
                    if ($('#playerModule__artworkBG').attr('src') != albumAPI.cover) {
                        $('#playerModule__artworkBG').attr('src', albumAPI.cover);
                    }
                    if ($('#playerModule__song__artwork').attr('src') != albumAPI.cover) {
                        $('#playerModule__song__artwork').attr('src', albumAPI.cover);
                    }
                } else {
                    $('#playerModule__artworkBG').attr('src', "/keyfm_logo.png");
                    $('#playerModule__song__artwork').attr('src', "/keyfm_logo.png");
                }
                if ($('#playerModule__song__info__artist').text() != data["playing"].artist) {
                    $('#playerModule__song__info__artist').text(data["playing"].artist);
                }
                if ($('#playerModule__song__info__song').text() != data["playing"].song) {
                    $('#playerModule__song__info__song').text(data["playing"].song);
                }
                //$('#trackHREF').attr('href', '/track/' + data.now_playing.song.song_id);
                data.currentDJ.autoDJ != true ? $('#playerModule__dj__name__span').text(data.currentDJ.username) : $('#playerModule__dj__name__span').text('Auto DJ');
            });
        });
      }, 2000);
    },
    async togglePlay() {

        function fadeIn(){return new Promise(e=>{audio.volume=0;let t=Number(localStorage.getItem("KEYFM_VOLUME"))||.5,a=setInterval(()=>{audio.volume+t/10>=t?(clearInterval(a),e(0)):audio.volume+=t/10},40)})}function fadeOut(){let e=Number(localStorage.getItem("KEYFM_VOLUME"))||.5;return new Promise(t=>{let a=setInterval(()=>{audio.volume-e/10<=0?(clearInterval(a),t()):audio.volume-=e/10},40)})}$(window).on("pause",()=>{fadeOut().then(()=>{audio.pause()})});

        var playingStatus = $('#playBttn').attr('KEYFM_PLAYING');
        const audio = $('#KeyFMAudio')[0];
        let playing = false;
        if (playingStatus === 'true') {
            localStorage.setItem('KEYFM_PLAYING', 'false');
            fadeOut().then(() => {
                audio.pause();
            });
            audio.crossOrigin = "";
            playing = false;
            $('#playBttn').attr('KEYFM_PLAYING', 'false');
            $('#playBttn').html('<ion-icon name="play"></ion-icon>');
        } else {
            localStorage.setItem('KEYFM_PLAYING', 'true');
            audio.crossOrigin = "anonymous";
            audio.src = `https://radio.keyfm.net`;
            audio.play();
            await fadeIn();
            $('#playBttn').html('<div class="lds-ring"><div></div><div></div><div></div><div></div></div>');
            setInterval(()=> {
                if(audio.paused == true){
                    return false;
                } else if(audio.paused == false && playing === false){
                    playing = true;
                    $('#playBttn').attr('KEYFM_PLAYING', 'true');
                    $('#playBttn').html('<ion-icon name="stop"></ion-icon>');
                }
            }, 100);
        }
      }
  },
  created() {
        this.statData();
  },
  mounted() {
      const audio = $('#KeyFMAudio')[0];
      if ((audio.currentSrc).length <= 0) {
        console.log('src empty')
          $('#playBttn').html('<ion-icon name="play"></ion-icon>');
          $('#playBttn').attr('KEYFM_PLAYING', 'false');
      } else {
        if (localStorage.getItem('KEYFM_PLAYING')) {
            if (localStorage.getItem('KEYFM_PLAYING') === 'true') {
                $('#playBttn').html('<ion-icon name="stop"></ion-icon>');
                $('#playBttn').attr('KEYFM_PLAYING', 'true');
            } else if (localStorage.getItem('KEYFM_PLAYING') === 'false') {
                $('#playBttn').html('<ion-icon name="play"></ion-icon>');
                $('#playBttn').attr('KEYFM_PLAYING', 'false');
            }
        }
      }
  },
  beforeUnmount () {
      clearInterval(this.getStats);
  },
  beforeDestroy() {
        clearInterval(this.getStats);
    }
}
</script>