<template>
    <div class="timetableModuleV1">
        <div class="row equal">
            <div class="col-xs-12 col-md-4 miniTimetableItem dj">
                <div class="avatar" data-ubcard-id="4960"><img src="https://media-exp1.licdn.com/dms/image/C5603AQG876KUkwthMw/profile-displayphoto-shrink_200_200/0/1583009597015?e=1648080000&v=beta&t=lp4iVu_CZDlwEx3kCqUeVsvKLsOt8D_rLeOlZx_vAPU"></div>
            </div>
        </div>
    </div>
    <!-- <div class="timetableModule">
        <div class="timetableModule__base">
            <div class="timetableModule__background"><img src="https://media-exp1.licdn.com/dms/image/C5603AQG876KUkwthMw/profile-displayphoto-shrink_200_200/0/1583009597015?e=1648080000&v=beta&t=lp4iVu_CZDlwEx3kCqUeVsvKLsOt8D_rLeOlZx_vAPU" id="timetableModule__nextUp"/></div>

            <div class="timetableModule__slot1">
                <span class="timetableModule__slot1__time">00:00</span>
                <img src="https://media-exp1.licdn.com/dms/image/C5603AQG876KUkwthMw/profile-displayphoto-shrink_200_200/0/1583009597015?e=1648080000&v=beta&t=lp4iVu_CZDlwEx3kCqUeVsvKLsOt8D_rLeOlZx_vAPU" class="timetableModule__slot1__avatar"/>
                <span class="timetableModule__slot1__name">Jack Hansen</span>
            </div>
        </div>
    </div> -->
</template>
<script>
//import $ from 'jquery';
export default {
  name: 'TimetableModule',
  data () {
      return {
          getTimetable: null
      }
  },
  methods: {
    statData() {
        // $.getJSON('http://localhost:4000', function(data) {
        //     console.log(data);
        //     data = data.radio;
        //     $('#playerModule__artworkBG').attr('src', data[0].now_playing.song.covers.xl || data[0].now_playing.song.covers.big);
        //     $('#playerModule__song__artwork').attr('src', data[0].now_playing.song.covers.xl || data[0].now_playing.song.covers.big);
        //     $('#playerModule__song__info__artist').text(data[0].now_playing.song.artist);
        //     $('#playerModule__song__info__song').text(data[0].now_playing.song.title);
        //     data[0].now_playing.presenter != null ? $('#playerModule__dj__name__span').text(data[0].now_playing.presenter.name) : $('#playerModule__dj__name__span').text('Auto DJ');
        // });
    }
  },
  created() {
  },
  beforeUnmount () {
  },
  beforeDestroy() {
    }
}
</script>