<template>
  <div class="home">
    
    <div class="homeContent">
      <div class="homeContentSub">
        <div class="col-xs-12 col-md-8">
          <div class="row">
            <!-- <div class="col-xs-12 col-md-4"> -->
              <TimetableModule></TimetableModule>
            <!-- </div> -->
          </div>
        </div>
        <div class="col-xs-12 col-md-4">
          <h2>Test</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import TimetableModule from '@/components/Timetable.vue'
export default {
  name: 'HomeView',
  components: {
    TimetableModule
  }
}
</script>
