import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import $ from 'jquery'
import VueContentPlaceholders from 'vue-content-placeholders'


import './assets/css/style.css'
// import './assets/css/bootstrap_v5.min.css'
import 'animate.css'


const app = createApp(App);
// app.config.isCustomElement = tag => tag.startsWith('ion-');
app.use(VueAxios, axios);
app.use(router);
app.use($);
app.use(VueContentPlaceholders)
app.mount('#keyfm');

// createApp(App).use(router).use(VueAxios, axios).use($).mount('#app')
